<template>
  <div v-if="isDataInited">
    <vx-card class="mb-3" :title="'#' + initData.header.no_komisi" collapse-action :is-refresh="isLoading">
      <div class="vx-row">
        <div class="vx-col">
          <div class="img-container mb-4">
            <img alt="" class="w-32" src="@/assets/svg/person.svg"/>
          </div>
        </div>
        <div class="vx-col flex-1">
          <table class="table-info">
            <tr>
              <td class="font-semibold">Staf Penerima</td>
              <td>: {{ initData.header.nama_staf || '-' }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Jabatan</td>
              <td>: {{ initData.header.nama_jabatan || '-' }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Telp</td>
              <td>: {{ initData.header.telp_staf || '-' }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Email</td>
              <td>: {{ initData.header.email_staf || '-' }}</td>
            </tr>
          </table>
        </div>
        <div class="vx-col flex-1">
          <table class="table-info">
            <tr>
              <td class="font-semibold">No. Komisi</td>
              <td>: {{ initData.header.no_komisi || '-' }}</td>
            </tr>
            <tr>
              <td class="font-semibold">No. Kontrak</td>
              <td>: {{ initData.header.no_kontrak || '-' }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Total Komisi</td>
              <td>: {{ initData.header.total_komisi | idr }} ({{ initData.header.persen_komisi }}%)</td>
            </tr>
            <tr>
              <td class="font-semibold">Tgl Komisi</td>
              <td>: {{ initData.header.tgl_komisi || '-' }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="vx-row mt-3">
        <div class="vx-col flex flex-wrap space-x-2">
          <vs-button type="filled" color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-database" :to="{ name: 'master.staf' }">Data Master Staf</vs-button>
          <vs-button type="filled" color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-book-open" :to="{ name: 'marketing.kontrak.detail', params: { idKontrak: initData.header.id_kontrak } }">Detail Kontrak</vs-button>
          <vs-button type="flat" color="primary" size="small" class="px-3"  icon-pack="feather" icon="icon-refresh-cw" @click="refresh">Refresh</vs-button>
        </div>
      </div>
    </vx-card>

    <vx-card>
      <vs-tabs alignment="fixed" v-model="activeTab">
        <vs-tab label="Detail Komisi">
          <TabDetailKomisi ref="tabDetailKomisi" :isActive="activeTab === 0" :init-data="initData"/>
        </vs-tab>
        <vs-tab label="Ketentuan & Claim Pencairan">
          <TabKetentuan ref="tabKetentuan" :isActive="activeTab === 0" :init-data="initData" @claimed="refresh"/>
        </vs-tab>
        <vs-tab label="Status Pembayaran">
          <TabStatusPembayaran ref="tabStatusPembayaran" :isActive="activeTab === 0" :init-data="initData"/>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import KomisiRepository from '@/repositories/marketing/komisi-repository'
import moduleKomisiDetail from '@/store/modules/marketing/komisi/komisi-detail.store'

export default {
  name: 'KomisiDetail',
  components: {
    TabDetailKomisi: () => import('@/views/pages/marketing/komisi/tabs/detail-komisi/TabDetailKomisi'),
    TabKetentuan: () => import('@/views/pages/marketing/komisi/tabs/ketentuan/TabKetentuan'),
    TabStatusPembayaran: () => import('@/views/pages/marketing/komisi/tabs/status-pembayaran/TabStatusPembayaran')
  },
  mounted () {
    this.getInitData()
  },
  computed: {
    storeKomisiDetail () {
      return this.$store.state.marketing.komisiDetail
    },
    activeTab: {
      get () {
        return this.storeKomisiDetail.activeTab
      },
      set (value) {
        this.$store.commit('marketing/komisiDetail/SET_ACTIVE_TAB', value)
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      initData: {
        header: {},
        rules: [],
        cairHistory: [],
        payments: []
      }
    }
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idKomisi = this.$route.params.idKomisi
      KomisiRepository.show(idKomisi)
        .then(response => {
          this.initData = response.data.data
          this.isDataInited = true
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    refresh () {
      this.getInitData()
    }
  },
  beforeCreate () {
    this.$store.registerModule(['marketing', 'komisiDetail'], moduleKomisiDetail)
  },
  beforeDestroy () {
    this.$store.commit('marketing/komisiDetail/RESET_STATE')
    this.$store.unregisterModule(['marketing', 'komisiDetail'])
  }
}
</script>

<style scoped lang="scss">
table.table-info {
  td {
    vertical-align: top;
    min-width: 140px;
    padding-bottom: .5rem;
    word-break: break-all;
  }
}
</style>
