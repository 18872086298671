import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/komisi'

export default {
  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  show (idStaf) {
    return httpClient.get(`${endpoint}/${idStaf}`)
  },

  showPayment (idKomisiPayment) {
    return httpClient.get(`${endpoint}/payments/${idKomisiPayment}`)
  },

  claim (params) {
    return httpClient.post(`${endpoint}`, params)
  }
}
